import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// Generate Order Data


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
},
}));

export default function Orders() {
  const classes = useStyles();
  return (

    <Grid container spacing={3}>
          <h3>Orders</h3>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Title>404 Not found</Title>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}