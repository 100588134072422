import React from 'react'
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import useStyles from '../Theme/Styles'
import { Container, Paper, Typography } from '@material-ui/core'
import { Link } from "react-router-dom";
import YouTubeIcon from '@material-ui/icons/YouTube';
// import SvgIcon from '@material-ui/core/SvgIcon';
// import { makeStyles } from '@material-ui/core/styles';
// import useStyles from './styles'
import { List, ListItem, ListItemIcon, ListItemText, Link as MuiLink, Divider } from '@material-ui/core'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import FavoriteIcon from '@material-ui/icons/Favorite';
import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import ListSubheader from '@material-ui/core/ListSubheader';
import WebIcon from '@material-ui/icons/Web';
import GitHubIcon from '@material-ui/icons/GitHub';
import Box from '@material-ui/core/Box'
import clsx from 'clsx';

export default function Footer() {
    // const classes = useStyles();
    const classes = useStyles();
    //const classFavoriteIcon = heartStyle();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <Box>
            <div className={classes.seeMore}>

            </div>
            <Container className={classes.containerfooter} >
                {/* <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        test
                    </Paper>
                </Grid> */}
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Follow Us
                                </ListSubheader>
                            } >
                            <ListItem button href="https://twitter.com/Minaprotocol" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><TwitterIcon /></ListItemIcon>
                                <ListItemText primary={"@MinaProtocol"} />
                            </ListItem>
                            <ListItem button href="https://twitter.com/Minascan" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><TwitterIcon /></ListItemIcon>
                                <ListItemText primary={"@Minascan"} />
                            </ListItem>
                            <Divider />
                            <ListItem button href="https://twitter.com/Minascan" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><TelegramIcon /></ListItemIcon>
                                <ListItemText primary={"MinaProtocol"} />
                            </ListItem>
                            <ListItem button href="https://twitter.com/Minascan" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><TelegramIcon /></ListItemIcon>
                                <ListItemText primary={"MinaIndia"} />
                            </ListItem>
                            {/* </Link> */}
                            {/* <MuiLink href="https://minaprotocol.com/" variant="body2" target="_blank" rel="noopener">
                            Mina
                        </MuiLink> */}
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Visit Us
                                </ListSubheader>
                            } >
                            <ListItem button href="https://minaprotocol.com/" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><WebIcon /></ListItemIcon>
                                <ListItemText primary={"minaprotocol.com"} />
                            </ListItem>
                            <ListItem button href="https://github.com/minaProtocol/" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><GitHubIcon /></ListItemIcon>
                                <ListItemText primary={"Mina GitHub"} />
                            </ListItem>
                            <ListItem button href="https://www.youtube.com/channel/UCWzKMFfIlMzHUXKSnYot5HA" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><YouTubeIcon /></ListItemIcon>
                                <ListItemText primary={"Mina YouTube "} />
                            </ListItem>
                        </List>

                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Resources
                                </ListSubheader>
                            } >
       
                            <ListItem button href="https://github.com/nerdvibe/awesome-mina" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><WebIcon /></ListItemIcon>
                                <ListItemText primary={"Awesome mina resources"} />
                            </ListItem>
                            <ListItem button href="https://learn.figment.io/" variant="body2" target="_blank" rel="noopener">
                                <ListItemIcon><WebIcon /></ListItemIcon>
                                <ListItemText primary={"https://learn.figment.io/"} />
                            </ListItem>
                        </List>
                        {/* <Divider />
                        <List>
                            <ListItem button>
                                <ListItemText primary="Trash" />
                            </ListItem>
                        </List> */}
                    </Grid>


                </Grid>
            </Container>
            <Grid item xs={12} className={classes.container}>
                <Typography variant="body1" align="center">
                    Made with <FavoriteIcon color="error" fontSize="small" /> for&nbsp;
                        <MuiLink href="https://minaprotocol.com/" variant="body2" target="_blank" rel="noopener">
                        Mina
                        </MuiLink> and&nbsp;
                        <MuiLink href="https://learn.figment.io/" variant="body2" target="_blank" rel="noopener">
                        Figment Learn
                        </MuiLink>
                              &nbsp;in India.
                   </Typography>
            </Grid>
        </Box>
    )
}
