import React, { useState, useEffect } from 'react';
// import clsx from 'clsx';
// import Link from '@material-ui/core/Link';
import { Link } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import AppBar from '@material-ui/core/AppBar';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
// import Transactions from './Transactions';
// import Blocks from './Blocks';
import { withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core';
import useStyles from '../Theme/Styles'
import axios from '../Constants/axios'
import { diffTime } from '../Constants/Constants'


function SingleAccount(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  // console.warn(props)
  const [account, setAccount] = useState([])
  // useEffect(() => {
  //   let mounted = true;
  //   axios.get(`https://mina--mainnet--indexer.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/accounts/` + props.match.params.creator)
  //     .then((result) => {

  //       // console.warn(result)
  //       if (mounted) {
  //         setAccount(result.data)
  //       }
  //     })
  //   return () => {
  //     mounted = false;
  //   }
  // }, [props.match.params.creator])
  useEffect(()=>{
    async function fetchData(){
        const request = await axios.get("/accounts/" + props.match.params.creator
        );
        setAccount(request.data);  
        return request;
    }
    fetchData(); 
},[props.match.params.creator]);

  const [transactions, setTransactions] = useState([])
  useEffect(()=>{
    async function fetchData(){
        const request = await axios.get("/transactions?sender" + props.match.params.creator
        );
        setTransactions(request.data);  
        return request;
    }
    fetchData(); 
},[props.match.params.creator]);

  // useEffect(() => {
  //   let mounted = true;
  //   axios.get(`https://mina--mainnet--indexer.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/transactions?sender` + props.match.params.creator)
  //     .then((result) => {

  //       // console.warn(result)
  //       if (mounted) {
  //         setTransactions(result.data)
  //       }
  //     })
  //   return () => {
  //     mounted = false;
  //   }
  // }, [props.match.params.creator])


  const [blocks, setBlocks] = useState([])
  useEffect(()=>{
    async function fetchData(){
        const request = await axios.get("/blocks?creator" + props.match.params.creator
        );
        setBlocks(request.data);
        return request;
    }
    fetchData(); 
},[props.match.params.creator]);
  // useEffect(() => {
  //   let mounted = true;
  //   axios.get(`https://mina--mainnet--indexer.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/blocks?creator` + props.match.params.creator)
  //     .then((result) => {

  //       // console.warn(result)
  //       if (mounted) {
  //         setBlocks(result.data)
  //       }
  //     })
  //   return () => {
  //     mounted = false;
  //   }
  // }, [props.match.params.creator])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Paper className={classes.paper} >
          {/* className={classes.paper} */}
          <React.Fragment>
            <Typography variant="h6" color="primary" gutterBottom >Overview</Typography>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Public Key</TableCell>
                  <TableCell>{account.public_key}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Balance</TableCell>
                  <TableCell>{account.balance / 1000000000} Mina</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Value(approx)</TableCell>
                  <TableCell>{(account.balance / 1000000000) * 0.25} USD</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>stake</TableCell>
                  <TableCell> {account.stake / 1000000000} Mina</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className={classes.seeMore}>
            </div>
          </React.Fragment>
        </Paper>
      </Grid>
      <Grid item xs={12} >

        <div className={classes.root}>
          <Paper>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Transactions" {...a11yProps(0)} />
                <Tab label="Block produced" {...a11yProps(1)} />
                <Tab label="Snark Jobs" {...a11yProps(2)} />
                {/* <Tab label="Delegated to" {...a11yProps(3)} />
                <Tab label="Delegated received" {...a11yProps(4)} /> */}

              </Tabs>
            </AppBar>
          </Paper >
          <TabPanel value={value} index={0}>
            <Paper className={classes.paper} >
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow >
                      <TableCell>Transaction Hash</TableCell>
                      <TableCell>Date & Time</TableCell>
                      <TableCell>Block</TableCell>
                      <TableCell>Receiver</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions?.map((transactions, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Link to={"/tx/" + transactions?.hash} className={classes.link} >
                            {transactions?.hash?.slice(0, 10)}....
                        {transactions?.hash?.slice((transactions?.hash?.length - 4))}
                          </Link>
                        </TableCell>
                        <TableCell>{transactions?.time ?? "loading"} &nbsp; ({diffTime(transactions?.time)} mins ago)</TableCell>
                        <TableCell>
                          <Link to={"/block/" + transactions?.block_height} className={classes.link} >
                            {transactions?.block_height ?? "loading"}
                          </Link>
                        </TableCell>
                        <TableCell>    <Link to={"/account/" + transactions?.receiver} className={classes.link} >
                          {transactions?.receiver?.slice(0, 10)}....
                      {transactions?.receiver?.slice((transactions?.receiver?.length - 4))}
                        </Link>
                        </TableCell>
                        <TableCell>{transactions?.type ?? "loading"}</TableCell>
                        <TableCell>{transactions?.status ?? "loading"}</TableCell>

                        <TableCell align="right">{((transactions?.amount) / 1000000000) ?? "loading"} Mina</TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </Grid>
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Paper className={classes.paper} >
              <Grid item xs={12}>

                <Table size="small">
                  <TableHead>
                    <TableRow >
                      <TableCell>Height</TableCell>
                      <TableCell>Ago</TableCell>
                      <TableCell>Canonical</TableCell>
                      <TableCell>Transactions</TableCell>
                      <TableCell>Epoch</TableCell>
                      <TableCell>Slot</TableCell>
                      <TableCell align="right">Reward</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {blocks?.map((blocks, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Link to={"/block/" + blocks?.height} className={classes.link} >
                            {blocks?.height ?? "loading"}
                          </Link>
                        </TableCell>
                        <TableCell>{blocks?.time ?? "loading"} &nbsp; ({diffTime(blocks?.time)} mins ago)</TableCell>
                        <TableCell>
                          {((blocks?.canonical === true) ? "True" : "False")}</TableCell>
                        <TableCell>{blocks?.transactions_count ?? "loading"}</TableCell>
                        <TableCell>{blocks?.epoch ?? "loading"}</TableCell>
                        <TableCell>{blocks?.slot ?? "loading"}</TableCell>
                        <TableCell align="right">{((blocks?.coinbase) / 1000000000) ?? "loading"} Mina</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

              </Grid>
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Paper className={classes.paper} >
              List of completed snark jobs.</Paper>
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <Paper className={classes.paper} >
              details to whome coins have delegated to.</Paper>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Paper className={classes.paper} >
              accounts who are delegated to us
              </Paper>
          </TabPanel> */}

        </div>

      </Grid>
      {/* Recent Deposits */}
      {/* <Grid item xs={12} md={6} lg={6}>
        <Paper>
          className={fixedHeightPaper}
          this is single account page
                </Paper>
      </Grid> */}
    </Grid>
  );
}
function TabPanel(props) {
  const { children, value, index } = props;
  return (<>
    {
      value === index && (
        <>{children}</>
      )
    }
  </>
  )
}
export default withRouter(SingleAccount);