import React, { useState } from 'react'
import { Switch, Route } from "react-router-dom";
import {
    Box, createMuiTheme, ThemeProvider, Switch as MuiSwitch, CssBaseline, Container, Grid
} from '@material-ui/core'
import Dashboard from './Dashboard'
//import Dashboard from '../../V2/Components/Dashboard'
import Header from './Header'
//import Header from '../../V2/Components/Header'
import Footer from './Footer'
// import Blocks from '../../V2/Components/Blocks'
import Blocks from './Blocks'
import SingleAccount from './SingleAccount'
import Transactions from './Transactions'
import Template from './SingleAccount'
import SingleBlock from './SingleBlock'
import SingleTransaction from './SingleTransaction'
import Page404 from './Page404'
import SearchBox from './SearchBox'
import useStyles from '../Theme/Styles'
// import useStyles from '../../V2/theme/styles'
// import useMediaQuery from '@material-ui/core/useMediaQuery';




export default function App() {
    

    const classes = useStyles();
    const [darkMode, setDarkMode] = useState(false);
    const darkTheme = createMuiTheme({
        palette: {
            type: 'dark',
            primary: {
                main: '#ff5700',  //
            },
            secondary: { 
                main: '#666',  //#e65100
            },
        },
        props: {
            MuiAppBar: {
              color: '#999', //#333
            },
          },
    });
    const lightTheme = createMuiTheme({
        palette: {
            type: 'light',
            primary: {
                main: '#ff5700',  //#e65100  
            },
            secondary: { 
                main: '#1ab7ea',  //#e65100
            },
        },
    });
    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    // if(!prefersDarkMode) setDarkMode(darkMode)
    return (
        <Box>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                <CssBaseline />
                <Header />
                <Box display={{ xs: 'block', sm: 'none' }} m={1} position="absolute" top={5} right={68} zIndex="appBar">
                    <MuiSwitch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
                </Box>
                <Box display={{ xs: 'none', sm: 'block' }} m={1} position="absolute" top={5} right={450} zIndex="appBar">
                    <MuiSwitch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
                </Box>

                
                    <Container maxWidth="lg" className={classes.container} >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SearchBox />
                            </Grid>
                        </Grid>
                        <main>
                        <Switch>
                            <Route path="/blocks"><Blocks /></Route>
                            {/* <Route path="/accounts"><SingleAccount /></Route> */}
                            {/* <Route path="/memorypool"><Transactions /></Route> */}
                            <Route path="/transactions"><Transactions /></Route>
                            <Route path="/template"><Template /></Route>
                            <Route path="/account/:creator"><SingleAccount /></Route>
                            <Route path="/block/:height"><SingleBlock /></Route>
                            <Route path="/tx/:hash"><SingleTransaction /></Route>

                            <Route exact path="/"><Dashboard /></Route>
                            <Route path="*"><Page404 /></Route>
                        </Switch>
                        </main>
                        <footer>
                            <Footer />
                        </footer>
                    </Container>
                    {/* </Grid> */}
                    {/* </Container> */}
          


            </ThemeProvider>
        </Box>
    )
}
