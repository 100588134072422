import React, { useState, useEffect } from 'react';
// import clsx from 'clsx';
// import Link from '@material-ui/core/Link';
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Tab from '@material-ui/core/Tab'
// import Tabs from '@material-ui/core/Tabs'
// import Transactions from './Transactions';
// import Blocks from './Blocks';
import { withRouter, Link } from 'react-router-dom'
import useStyles from '../Theme/Styles'
import axios from '../Constants/axios'
import { TramRounded } from '@material-ui/icons';
function diffTime(e) {
  //alert(e)
  const currentDate = new Date().getTime() / (1000 * 60);

  const blockchainDate = new Date(e).getTime() / (1000 * 60);
  const diffMin = Math.floor(currentDate - blockchainDate);
  if (e === null) { return null } else { return diffMin }
}

// Generate Order Data
// function createData(id, date, name, shipTo, paymentMethod, amount) {
//   return { id, date, name, shipTo, paymentMethod, amount };
// }

// const rows = [
//   createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
//   createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
//   createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
//   createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
//   createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
// ];

// function preventDefault(event) {
//   event.preventDefault();
// }

// const useStyles1 = makeStyles((theme) => ({
//   seeMore: {
//     marginTop: theme.spacing(3),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//   },
// }));


function SingleTransaction(props) {
  //  console.log(props)
  //   constructor(props) {
  //     super(props);

  //     this.state = {
  //         response: {...}
  //     };
  // }
  //console.warn(props)
  const [transaction, setTransaction] = useState([])
  const [errors, setError] = useState([0])

  // useEffect(() => {
  //   let mounted = true;
  //   fetch("https://mina--mainnet--indexer.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/transactions/" + props.match.params.hash).then((result) => {
  //     result.json().then((resp) => {
  //       // console.warn(resp)
  //       if (mounted) {
  //         Settransaction(resp)
  //       }
  //     })
  //   })
  //   return () => {
  //     mounted = false;
  //   }
  // }, [props.match.params.hash])

  useEffect(()=>{
    async function fetchData(){
      try { 
        const request = await axios.get("/transactions/" + props.match.params.hash
        );
        // console.log(request);
        setTransaction(request.data);  // in above setBlock .data taken after seeing the console.log of reuqest
        return request;
      } catch (error) {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            setError(error.response.status);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        console.log(error);
    }
        
      }
    //you cannot use async function inside useEffect hence we do this way. create function above then call back again.
    fetchData(); 
    // if [], run once when this function or page loads, and then dont run again

},[props.match.params.hash]);

  // console.log(blocks)
  // const [value, setValue] = useState(0)
  // const handleTabs = (e, val) => {
  //   setValue(val)
  //}
  // const [data, dataSet] = useState([])

  // useEffect(() => {

  //   async function fetchMyAPI() {
  //     let response = await fetch('https://mina--mainnet--indexer.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/transactions/' + props.match.params.hash)
  //     response = await response.json()
  //     dataSet(response)
  //   }

  //   fetchMyAPI()
  // }, [props.match.params.height])
  // console.log(data)

  //const classes = useStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  // {
  //   data && data.map((height: number, hash: any) => {
  const classes = useStyles();
  return (

    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Paper className={classes.paper} >
          {/* className={classes.paper} */}
          <React.Fragment>
           
            <Title>Transaction Details</Title>
           
          
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell> {(errors ===  404) ? "Record not found" : (transaction?.id ?? "loading...")} </TableCell>
                  {/* <TableCell> {blocks?.block?.height}</TableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell>Transaction hash</TableCell>
                  <TableCell>{(errors ===  404) ? "Record not found" : (transaction?.hash ?? "loading...")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>{(errors ===  404) ? "Record not found" : (transaction?.time ?? "loading...")+ " (" + diffTime(transaction?.time)+ "mins ago)"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Amount </TableCell>
                  <TableCell> {(errors ===  404) ? "Record not found" : ((transaction?.amount / 1000000000) ?? "loading...") + " Mina"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>{(errors ===  404) ? "Record not found" : (transaction?.type ?? "loading...")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transaction fee</TableCell>
                  <TableCell>{(errors ===  404) ? "Record not found" : ((transaction?.fee / 1000000000) ?? "loading...")+" Mina"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Memo</TableCell>
                  <TableCell>{(errors ===  404) ? "Record not found" : (transaction?.memo ?? "null")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nonce</TableCell>
                  <TableCell>{(errors ===  404) ? "Record not found" : (transaction?.nonce ?? "null")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sender</TableCell>
                  <TableCell>
                  {(errors ===  404) ? "Record not found" : (<Link to={"/account/" + transaction?.sender} className={classes.link} >
                   {(transaction?.sender ?? "loading...")}
                    </Link>)}


                    {/* <Link to={"/account/" + transaction?.sender} className={classes.link} >
                    {(errors ===  404) ? "Record not found" :(transaction?.sender ?? "loading...")}
                    </Link> */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Receiver</TableCell>
                  <TableCell>
                  {(errors ===  404) ? "Record not found" : (<Link to={"/account/" + transaction?.receiver} className={classes.link} >
                      {transaction?.receiver ?? "loading..."}
                    </Link>)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Block Height</TableCell>
                  <TableCell>
                      {(errors ===  404) ? "Record not found" : (<Link to={"/block/" + transaction?.block_height} className={classes.link} >
                      {transaction?.block_height ?? "loading..."}
                    </Link>)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Block Hash</TableCell>
                  <TableCell>
                  {(errors ===  404) ? "Record not found" : (<Link to={"/block/" + transaction?.block_hash} className={classes.link} >
                      {transaction?.block_hash ?? "loading..."}
                    </Link>)}
                    </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Failure reason</TableCell>
                  <TableCell>{transaction?.failure_reason ?? "null"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Secondary sequence number</TableCell>
                  <TableCell>{transaction?.secondary_sequence_number ?? "null"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>sequence number </TableCell>
                  <TableCell>{transaction?.sequence_number ?? "null"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{transaction?.status ?? errors }</TableCell>
                </TableRow>
              </TableBody>

            </Table>
            <div className={classes.seeMore}>

            </div>

          </React.Fragment>
  
        </Paper>
      </Grid>
      {/* <Grid item xs={12} >
        <Paper className={fixedHeightPaper}>
          <Tabs value={value} onChange={handleTabs}>
            <Tab label="Transactions" />
            <Tab label="Validated Blocks" />
            <Tab label="Completed Snark jobs" />
          </Tabs>
          <TabPanel value={value} index={0}><Transactions /></TabPanel>
          <TabPanel value={value} index={1}><Blocks /></TabPanel>
          <TabPanel value={value} index={2}>snark jobs</TabPanel>
        </Paper>
      </Grid> */}
      {/* Recent Deposits */}
      {/* <Grid item xs={12} md={6} lg={6}> */}
        {/* <Paper> */}
          {/* className={fixedHeightPaper} */}
          {/* others */}
                {/* </Paper> */}
      {/* </Grid> */}
    </Grid>
  );
  //  }}
}
// function TabPanel(props) {
//  const {children, value, index}=props;
//   return (<>
//     {
//       value === index && (
//        <>{children}</>
//           )
//     }
//     </>
//  )
// }
export default withRouter(SingleTransaction);