import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Components/Title';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import axios from '../Constants/axios'
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import useStyles from '../Theme/Styles'
import { formatter,diffTime } from '../Constants/Constants'
import { useQuery } from '@apollo/client'
import { DASHBOARD_STAT } from '../GraphQL/Queries'

export default function Dashboard() {
    const [status, setStatus] = useState([]);
    const [totalCoins, setTotalCoins] = useState([]);
    const { error, loading, data } = useQuery(DASHBOARD_STAT, {
        pollInterval: 300000, // 300 seconds
    });

    //const [ blocks, setBlocks] = useState([]);
    useEffect(() => {
        let mounted = true;
        if ((data) && (mounted)) {
            setStatus(data);
            let iterator = data?.bestChain?.values()
            let totalCoins = 0;
            for (let elements of iterator) {
                totalCoins = elements?.protocolState?.consensusState?.totalCurrency/1000000000;
              }
            setTotalCoins(totalCoins);
        }
        return () => {
            mounted = false;
        }
        
    }, [data])
    // console.log(data)
    // let iterator = status?.bestChain?.values()
    // let totalCoins = 0;
    // for (let elements of iterator) {
    //     totalCoins = elements?.protocolState?.consensusState?.totalCurrency/1000000000;
    //   }
    //   console.warn(totalCoins);
    //console.log(memPool)

    const [ blocks, setBlocks] = useState([]);
    useEffect(()=>{
        async function fetchData(){
            const request = await axios.get("/blocks?limit=10"
            );
            // console.log(request);
            setBlocks(request.data);  // in above setBlock .data taken after seeing the console.log of reuqest
            return request;
        }
        //you cannot use async function inside useEffect hence we do this way. create function above then call back again.
        fetchData(); 
        // if [], run once when this function or page loads, and then dont run again

    },[]);
    // console.warn(blocks);

    // const [transactions,setTransactions]=useState([])
    // useEffect(()=>{
    //     async function fetchData(){
    //         const request = await axios.get("/transactions?limit=10");
    //          console.log(request);
    //         setTransactions(request.data); 
    //     }
    //     fetchData(); 
    // },[])
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (

        <Box>
            <Grid container spacing={3}>

                {/* <Grid item xs={12} sm={12} md={8} lg={9}>
                <Paper className={fixedHeightPaper}>
                    <Chart />
                </Paper>
            </Grid> */}

                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Paper className={fixedHeightPaper}>
                        <Typography variant="h6" color="primary" gutterBottom >Latest Height</Typography>
                        <Typography variant="h5" gutterBottom >{status?.daemonStatus?.blockchainLength ?? "loading..."}</Typography>
                        <Typography variant="h6" color="primary" gutterBottom >Total Accounts</Typography>
                        <Typography variant="h5" gutterBottom >{(status?.daemonStatus?.numAccounts) ?? "loading..."}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Paper className={fixedHeightPaper}>
                        <Typography variant="h6" color="primary" gutterBottom >Current Supply</Typography>
                        {/* <Typography variant="h5" gutterBottom >{(Math.trunc((status?.bestChain[0]?.protocolState?.consensusState?.totalCurrency?? 1000000000 )/ 1000000000)).toLocaleString('en-US', { maximumFractionDigits: 2 })} Mina</Typography> */}
                        <Typography variant="h5" gutterBottom >
                                {(totalCoins).toLocaleString() ?? "loading..."}                        
                                </Typography>
                        <Typography variant="h6" color="primary" gutterBottom >Total Supply</Typography>
                        <Typography variant="h5" gutterBottom >2,000,000,000 Mina</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Paper className={fixedHeightPaper}>
                        <Typography variant="h6" color="primary" gutterBottom >Current Rate</Typography>
                        <Typography variant="h5" gutterBottom >$0.25</Typography>
                        <Typography variant="h6" color="primary" gutterBottom >Market Cap</Typography>
                        <Typography variant="h5" gutterBottom >{formatter.format(Math.trunc(totalCoins * 0.25))}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <React.Fragment>
                            <Title>Latest Blocks</Title>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Height</TableCell>
                                        <TableCell>Ago</TableCell>
                                        <TableCell>Creator</TableCell>
                                        <TableCell align="right">Transactions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {blocks?.map((item, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <Link to={"/block/" + item?.height} className={classes.link} >
                                                    {item?.height}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {diffTime(item?.time)} mins ago
                                          </TableCell>
                                            <TableCell>
                                                <Link to={"/account/" + item?.creator} className={classes.link} >
                                                    {item?.creator.slice(0, 10)}......{item?.creator?.slice((item?.creator?.length - 4))}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">{item?.transactions_count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div >
                                <Link to="/blocks" className={classes.link} >
                                    More Blocks...
                                </Link>
                            </div>
                        </React.Fragment>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <React.Fragment>
                            <Title>Pending Transactions in MemoryPool</Title>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Hash</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {status?.pooledUserCommands?.map((item, i) => (
                                        <TableRow key={i}>
                                            <TableCell><Link to={"/tx/" + item.hash} className={classes.link} >{item.hash.slice(0, 10)}......{item.hash.slice((item.hash.length - 4))}</Link></TableCell>
                                            <TableCell>{item?.kind}</TableCell>
                                            <TableCell align="right">{item?.amount / 1000000000} Mina</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div>
                                <Link to="/memorypool" className={classes.link} >
                                    More details...
                                </Link>
                            </div>
                        </React.Fragment>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12}>
                    <Paper className={fixedHeightPaper}>
                        <Typography variant="h5" color="primary" gutterBottom >What is Minascan?</Typography>
                        <Typography variant="subtitle1" gutterBottom >
                            Minascan is block explorer for MinaProtocol
                        </Typography>
                        <Typography variant="h5" color="primary" gutterBottom >What is Mina Protocol?</Typography>

                        <Typography variant="body1" gutterBottom >Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                        Corporis alias esse voluptates nam, labore ex non, nesciunt nostrum aperiam enim, consequuntur voluptate 
                        beatae perspiciatis nemo numquam dicta! Consequatur, fugit veritatis!
                        Officia, cumque error quibusdam quam provident saepe pariatur omnis, nam debitis porro 
                        aut distinctio facere nostrum voluptatem dolores ratione animi praesentium, incidunt facil
                        is itaque sed aliquid. Ratione ipsum facere nemo.</Typography>
                    </Paper>
                </Grid> */}
            </Grid>
        </Box>
    );
}