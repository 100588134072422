import React, { useState } from 'react'
import {
    AppBar, Box, Button, Toolbar,
    Typography, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BallotIcon from '@material-ui/icons/Ballot';
import ClassIcon from '@material-ui/icons/Class';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import CallMadeIcon from '@material-ui/icons/CallMade';
//import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
//import HowToVoteIcon from '@material-ui/icons/HowToVote';

//import { orange, deepOrange, lightBlue, deepPurple, green } from '@material-ui/core/colors'
import useStyles from '../Theme/Styles'
import { Link } from 'react-router-dom'

export default function Header() {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawer = () => {
        setDrawerOpen(true)
    }
    return (
        <header>
            <AppBar position='sticky' color='primary'>
                <Toolbar>
                    <Typography variant="h6" className={classes.title} >
                        Minascan.org
                    </Typography>
                    {/* <div className={classes.search}><SearchBox /></div> */}
                    {/* <Button color="inherit">Login</Button> */}
                    <Box display={{ xs: 'none', md: 'block' }} m={1}>

                        <Link to="/" className={classes.linkDesktop}>
                            <Button color="inherit">Home</Button>
                        </Link>
                        <Link to="/blocks" className={classes.linkDesktop}>
                            <Button color="inherit">Blocks</Button>
                        </Link>
                        {/* <Link to="/accounts" className={classes.linkDesktop}>
                            <Button color="inherit">Accounts</Button>
                        </Link> */}
                        <Link to="/transactions" className={classes.linkDesktop}>
                            <Button color="inherit">Transactions</Button>
                        </Link>
                            <Button color="inherit" href="https://hubble.figment.io/mina/chains/mainnet" variant="body2" target="_blank" rel="noopener">
                                Validators<CallMadeIcon /></Button>
                    </Box>

                    <Box display={{ xs: 'block', md: 'none' }} m={1}>
                        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawer} >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <List>
                    {/* <Typography> */}
                    <Link to="/" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary={"Home"} />
                        </ListItem>
                    </Link>
                    {/* </Typography> */}
                    <Link to="/blocks" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon><ViewWeekIcon /></ListItemIcon>
                            <ListItemText primary={"Blocks"} />
                        </ListItem>
                    </Link>
                    {/* <Link to="/accounts" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
                            <ListItemText primary={"Accounts"} />
                        </ListItem>
                    </Link> */}
                    <Link to="/transactions" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon><ReceiptIcon /></ListItemIcon>
                            <ListItemText primary={"Transactions"} />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                {/* <List>
                    <Link to="/currentepoc" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon><ClassIcon /></ListItemIcon>
                            <ListItemText primary={"Current Epoc LEdger"} />
                        </ListItem>
                    </Link>
                    <Link to="/nextepoc" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon><CollectionsBookmarkIcon /></ListItemIcon>
                            <ListItemText primary={"Next Epoc Ledger"} />
                        </ListItem>
                    </Link>
                    <Link to="/template" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon><BallotIcon /></ListItemIcon>
                            <ListItemText primary={"Template"} />
                        </ListItem>
                    </Link>
                </List> */}
            </Drawer>
        </header>
    )
}
