import React from 'react'
import { withRouter } from 'react-router-dom'; 
import {Box, TextField }from '@material-ui/core';
 import {InputAdornment, IconButton,} from '@material-ui/core';
 import SearchIcon from '@material-ui/icons/Search';
 import ClearIcon from '@material-ui/icons/Clear';
// import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
// import useStyles from './styles'

class SearchBox extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      nameError: "",
    }
  }


  submitForm (e) {
    e.preventDefault()
    
    if (this.state.name.startsWith("B62") && this.state.name.length === 55) {
      this.props.history.push('/account/'+this.state.name); // <--- The page you want to redirect your user to.
    }
    else if (Number(this.state.name)) {
        this.props.history.push('/block/'+this.state.name); // <--- The page you want to redirect your user to.
    }
    else if (this.state.name.startsWith("3") && this.state.name.length === 52) {
      this.props.history.push('/block/'+this.state.name); // <--- The page you want to redirect your user to.
    }
    else if (this.state.name.length === 40 || this.state.name.length === 53) {
        this.props.history.push('/tx/'+this.state.name); // <--- The page you want to redirect your user to.
    }
    else {
      this.setState(
        { nameError: "Wrong Public Key/Block Height/Block Hash or Transaction Hash" })
    }
  };
  handleMouseDown = (e) => {
    e.preventDefault();
  };
  handleReset = () => {
       this.setState({
      name: "",
      nameError: "",

    });
  };
  render() {
    
    return (
      <Box>
        <form onSubmit={this.submitForm.bind(this)}>
        <TextField id="minahash" value={this.state.name} type="text" variant="outlined"
        fullWidth  helperText={this.state.nameError} label="Search by Address / Hash / Height"
        onChange={(event) => { this.setState({ name: event.target.value})  }} 
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                  aria-label="clear text"
                  onClick={this.handleReset} 
                  onMouseDown={this.handleMouseDown}
                >
            {this.state.name ? <ClearIcon />: ""}</IconButton> 
              
              <IconButton
                  aria-label="search"
                  onClick={this.submitForm.bind(this)}
                  onMouseDown={this.handleMouseDown}
                >
              <SearchIcon /></IconButton>
            </InputAdornment>
          ),
        }}

        />

        {/* <p>{this.state.nameError}</p> 
        <Typography variant="caption"> this is error</Typography> */}
        {/* <IconButton onClick={() => this.submit()}><SearchIcon /></IconButton> */}
        
        </form>
      </Box>
    );
  }
}
export default withRouter(SearchBox);
