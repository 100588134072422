import { makeStyles } from '@material-ui/core' //fade
// import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>({
    container:{
    //backgroundColor: theme.palette.background.paper,
    //padding: theme.spacing(8,0,6)
     paddingTop: theme.spacing(2),
     paddingBottom: theme.spacing(4),
    },
    containerfooter:{
        MarginTop: theme.spacing(2),
        //MarginTop:theme.spacing(6),
        backgroundColor: theme.palette.secondary.main, //https://material-ui.com/customization/palette/
        //padding: theme.spacing(8,0,6)
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(4),
        },
    appBarSpacer: theme.mixins.toolbar,
    root: {
      flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    linkDesktop: {
        textDecoration: 'none',
        color: 'white',
    },
    paper: {
      padding: theme.spacing(2),
      //marginBottom: theme.spacing(3),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },

}));
export default useStyles;