import React from 'react';
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    HttpLink,
    from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Home from './Components/Home';

const errorLink = onError(error => {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
    if (networkError) console.log(`[Network error]: ${networkError}`, networkError);
});

const link = from([
    errorLink,
    new HttpLink({ uri: "https://mina-mainnet--graphql.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/graphql" }),
])
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
    typePolicies: {
        Query: {
          fields: {
            sortedHeights: (existing, { args, readField }) => {
              const direction = sortOrder();
              const blockHeights = [...(readField("blockHeights") || [])];
  
              return blockHeights.sort((a, b) => {
                const aName = readField("name", a);
                const bName = readField("name", b);
                if (direction === "DESC") {
                  if (aName < bName) return 1;
                  if (aName > bName) return -1;
                  return 0;
                } else {
                  if (aName > bName) return 1;
                  if (aName < bName) return -1;
                  return 0;
                }
              });
  
            },
            country: {
              read: (existing, { toReference, args }) => {
                const heightRef = toReference({
                  __tpename: "blockHeight",
                  code: args.blockHeight
                })
                return existing ?? heightRef;
              },
            },
          },
        },
        Country: {
          keyFields: ["blockHeight"],
          fields: {
            nameWithEmoji: {
              read: (_, { readField }) => {
                const blockHeight = readField("blockHeight")
                const publicKey = readField("publicKey")
  
                return `${publicKey} ${blockHeight}`;
              },
            },
          },
        },
      },
});
export const sortOrder = client.cache.makeVar("ASC");
export default function App() {
    return <ApolloProvider client={client}>
        <Home />
    </ApolloProvider>;
}
