import { gql } from '@apollo/client'

export const LOAD_STATUS = gql`
    query status {
        daemonStatus {
        syncStatus
        numAccounts
        blockchainLength
        }
    }
`
export const LOAD_DASHBOARD = gql`
query status {
    daemonStatus {
      syncStatus
      stateHash
      numAccounts
      chainId
      blockchainLength
      peers {
        host
      }
    }
    bestChain(maxLength: 10) {
      creatorAccount {
        publicKey
      }
      protocolState {
        blockchainState {
          utcDate
        }
        consensusState {
          blockHeight
          totalCurrency
        }
      }
      transactions {
        userCommands {
          amount
        }
      }
    }
    pooledUserCommands {
      hash
      amount
      kind
    } 
  }  
`
export const DASHBOARD_STAT = gql`
query status {
    daemonStatus {
      syncStatus
      stateHash
      numAccounts
      chainId
      blockchainLength
      peers {
        host
      }
    }
    bestChain(maxLength: 1) {
      protocolState {
        consensusState {
          totalCurrency
        }
      }
    }
    pooledUserCommands {
      hash
      amount
      kind
    } 
  }  
`


    // pooledUserCommands {
    //   hash
    //   amount
    //   feePayer {
    //     publicKey
    //   }
    // } 

    // query transactions {
    //   bestChain(maxLength: 10) {
    //     transactions {
    //       userCommands {
    //         amount
    //         hash
    //         feePayer {
    //           publicKey
    //         }
    //         receiver {
    //           publicKey
    //         }
    //       }
    //     }
    //   }
    // }


    // query block {
    //   bestChain(maxLength: 1) {
    //     creator
    //     stateHash
    //     stateHashField
    //     protocolState {
    //       blockchainState {
    //         date
    //         snarkedLedgerHash
    //       }
    //       previousStateHash
    //       consensusState {
    //         blockHeight
    //         blockchainLength
    //         epoch
    //         slot
    //         totalCurrency
    //       }
    //     }
    //     transactions {
    //       userCommands {
    //         amount
    //         fee
    //         from
    //         hash
    //       }
    //       coinbase
    //     }
    //   }
    // }