import React, { useState, useEffect } from 'react';
// import clsx from 'clsx';
import { Link } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import axios from '../Constants/axios'
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
// import Transactions from './Transactions';
// import Blocks from './Blocks';
import { withRouter } from 'react-router-dom'
import { diffTime } from '../Constants/Constants'
import useStyles from '../Theme/Styles'
// function diffTime(e) {
//   const currentDate = new Date().getTime() / (1000 * 60);
//   const date2 = new Date(e);
//   const blockchainDate = new Date(e).getTime() / (1000 * 60);
//   const diffMin = Math.floor(currentDate - blockchainDate);
//   return diffMin
// }

// Generate Order Data
// function createData(id, date, name, shipTo, paymentMethod, amount) {
//   return { id, date, name, shipTo, paymentMethod, amount };
// }

// const rows = [
//   createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
//   createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
//   createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
//   createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
//   createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
// ];

// function preventDefault(event) {
//   event.preventDefault();
// }

// const useStyles = makeStyles((theme) => ({
//   seeMore: {
//     marginTop: theme.spacing(3),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//   },
// }));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function SingleBlock(props) {
  const classes = useStyles();
  //   constructor(props) {
  //     super(props);

  //     this.state = {
  //         response: {...}
  //     };
  // }
  //console.warn(props)
  const [blocks, setBlock] = useState([])
  useEffect(()=>{
    async function fetchData(){
        const request = await axios.get("/blocks/" + props.match.params.height
        );
        // console.log(request);
        setBlock(request.data);  // in above setBlock .data taken after seeing the console.log of reuqest
        return request;
    }
    //you cannot use async function inside useEffect hence we do this way. create function above then call back again.
    fetchData(); 
    // if [], run once when this function or page loads, and then dont run again

},[props.match.params.height]);
  // useEffect(() => {
  //   let mounted = true;
  //   axios.get(`https://mina--mainnet--indexer.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/blocks/` + props.match.params.height)
  //     .then((result) => {

  //       // console.warn(result)
  //       if (mounted) {
  //         setblock(result.data)
  //       }

  //     })
  //   return () => {
  //     mounted = false;
  //   }
  // }, [props.match.params.height])

  // console.log(blocks)
  const [value, setValue] = useState(0)
  const handleTabs = (e, val) => {
    setValue(val)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Paper className={classes.paper}>
          {/* className={classes.paper} */}
          <React.Fragment>
            <Title>Overview</Title>
            <Table size="small">
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Block Height</TableCell>
                  <TableCell>{blocks?.block?.height ?? "loading"}</TableCell>
                  {/* <TableCell> {blocks?.block?.height}</TableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell>Block hash</TableCell>
                  <TableCell>{blocks?.block?.hash ?? "loading"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>{blocks?.block?.time} &nbsp; ({diffTime(blocks?.block?.time)} mins ago)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Block Confirmed </TableCell>
                  <TableCell> {((blocks?.block?.canonical === true) ? "Yes" : "No") ?? "loading"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transactions Count</TableCell>
                  <TableCell> {blocks?.block?.transactions_count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>creator</TableCell>
                  <TableCell>
                  <Link to={"/account/" + blocks?.block?.creator} className={classes.link} >
                     {blocks?.block?.creator}
                     </Link>
                     </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Block Reward</TableCell>
                  <TableCell> {((blocks?.block?.coinbase ) / 1000000000).toLocaleString()} Mina</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Snark Jobs</TableCell>
                  <TableCell> {blocks?.block?.snark_jobs_count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Coin supply</TableCell>
                  <TableCell> {(blocks?.block?.total_currency / 1000000000).toLocaleString()} Mina</TableCell>
                </TableRow>
              </TableBody>

            </Table>
            <div className={classes.seeMore}>

            </div>
          </React.Fragment>
        </Paper>
      </Grid>
      <Grid item xs={12} >
      <Paper>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Transactions" {...a11yProps(0)} />
                <Tab label="Snark Jobs" {...a11yProps(2)} />

              </Tabs>
            </AppBar>
            </Paper >
          <TabPanel value={value} index={0}>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
            
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tx Hash</TableCell>
                    <TableCell>Time </TableCell>
                    <TableCell>Sendor </TableCell>
                    <TableCell>Receiver </TableCell>
                    <TableCell>type </TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blocks?.transactions?.map((transactions, i) => (
                    <TableRow key={i}>
                      <TableCell>
                      <Link to={"/tx/" + transactions?.hash} className={classes.link} >
                        {transactions?.hash?.slice(0, 10)}....
                        {transactions?.hash?.slice((transactions?.hash?.length - 4))}
                        </Link>
                      </TableCell>
                      <TableCell>{transactions?.time ?? "loading"} &nbsp;&nbsp; {diffTime(transactions?.time)} mins ago</TableCell>
                      <TableCell>
                  {(transactions?.sender != null) ? (<Link to={"/account/" + transactions?.sender} className={classes.link} > 
                      
                      {transactions?.sender?.slice(0, 10)}....
                      {transactions?.sender?.slice((transactions?.sender?.length - 4))}
                  </Link>) : "...."  }
                      </TableCell>
                      <TableCell>
                      <Link to={"/account/" + transactions?.receiver} className={classes.link} >
                        {transactions?.receiver?.slice(0, 10)}....
                      {transactions?.receiver?.slice((transactions?.receiver?.length - 4))}
                      </Link>
                      </TableCell>
                      <TableCell>{transactions?.type ?? "null"}</TableCell>
                      
                      <TableCell align="right">{((transactions?.amount) / 1000000000) ?? "loading"} Mina</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            </Paper>
          </TabPanel>

          <TabPanel value={value} index={1}>
          <Paper className={classes.paper}>
          <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Prover</TableCell>
                    <TableCell>Time </TableCell>
                    <TableCell>Work Count </TableCell>
                    <TableCell align="right">fee</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blocks?.snark_jobs?.map((snark_jobs, i) => (
                    <TableRow key={i}>
                      <TableCell>
                      <Link to={"/account/" + snark_jobs?.prover} className={classes.link} >
                        {snark_jobs?.prover?.slice(0, 10)}....
                        {snark_jobs?.prover?.slice((snark_jobs?.prover?.length - 4))}
                        </Link>
                      </TableCell>
                      <TableCell>{snark_jobs?.time ?? "loading"} &nbsp;&nbsp; {diffTime(snark_jobs?.time)} mins ago</TableCell>
                      <TableCell>
                      {snark_jobs?.works_count ?? "loading"}
                  
                      </TableCell>
                      <TableCell align="right">
                      {(snark_jobs?.fee / 1000000000).toLocaleString() ?? "loading"} Mina
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
              </Paper>
         
            </TabPanel>
          {/* <TabPanel value={value} index={2}>snark jobs</TabPanel> */}

      </Grid>

      </Grid>
  );
  //  }}
}
function TabPanel(props) {
  const { children, value, index} = props;
  return (<>
        {
          value === index && (
            <>{children}</>
          )
        }
      </>
  )
}
export default withRouter(SingleBlock);