import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import axios from '../Constants/axios'
import { diffTime } from '../Constants/Constants'
import useStyles from '../Theme/Styles'
import Title from './Title'

export default function Blocks() {
  const classes = useStyles();
  const [blocks, setBlocks] = useState([])

  useEffect(()=>{

    async function fetchData(){
        const request = await axios.get("/blocks?limit=10"
        );
        // console.log(request);
        setBlocks(request.data);  // in above setBlock .data taken after seeing the console.log of reuqest
        return request;
    }
    //you cannot use async function inside useEffect hence we do this way. create function above then call back again.
    fetchData(); 
    // if [], run once when this function or page loads, and then dont run again

},[]);
  // console.log(blocks)
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Title>Latest Blocks</Title>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Canonical</TableCell>
                  <TableCell>Height</TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell>Block Producer</TableCell>
                  <TableCell>Transactions</TableCell>
                  <TableCell>Snarkers</TableCell>
                  <TableCell>Snark Jobs</TableCell>
                  <TableCell align="right">Reward</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blocks?.map((blocks, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {((blocks?.canonical === true) ? "True" : "False") ?? "loading"}
                    </TableCell>
                    <TableCell>
                      <Link to={"/block/" + blocks?.height} className={classes.link}>
                        {blocks?.height ?? "loading"}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {blocks?.time ?? "loading"} &nbsp;&nbsp; {diffTime(blocks?.time)} mins ago
                      </TableCell>
                    <TableCell>
                      <Link to={"/account/" + blocks?.creator} className={classes.link}>
                      {blocks?.creator.slice(0, 10)}......{blocks?.creator.slice(blocks?.creator.length - 4)}
        
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {blocks?.transactions_count ?? "loading"}
                    </TableCell>
                    <TableCell align="right">
                      {blocks?.snarkers_count ?? "loading"}
                    </TableCell>
                    <TableCell align="right">
                      {blocks?.snark_jobs_count ?? "loading"}
                    </TableCell>
                    <TableCell align="right">
                      {((blocks?.coinbase - blocks?.snark_jobs_fees + blocks?.transactions_fees) / 1000000000) ?? "loading"} Mina
                      </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className={classes.seeMore}>
            </div>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}